import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import Link from "../components/Link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SmallBG from "../images/404-bg-sm.png"
import SmallBGTwo from "../images/404-bg-sm-two.png"
import MediumBG from "../images/404-bg-md.png"
import LargeBG from "../images/404-bg-lg.png"
import XLargeBG from "../images/404-bg-xl.png"

const NotFoundContentWrapper = styled.div`
  ${tw`bg-no-repeat bg-cover bg-center md:flex md:items-center`}
  background-image: url(${SmallBG});
  height: calc(100vh - 116px);
  box-shadow: inset 0px 0px 20px 0px #fff;
  min-height: 660px;

  @media (min-width: 500px) {
    background-image: url(${SmallBGTwo});
    box-shadow: inset 0px 0px 20px 20px #fff;
  }

  @media (min-width: 768px) {
    background-image: url(${MediumBG});
  }

  @media (min-width: 1024px) {
    background-image: url(${LargeBG});
    height: calc(100vh - 125px);
  }

  @media (min-width: 1280px) {
    background-image: url(${XLargeBG});
  }

  .content-col {
    ${tw`max-w-xl w-full mx-auto pt-20 px-22 md:px-40 md:pt-0 lg:px-60 xxl:px-0`}

    h1 {
      ${tw`font-fb text-black-dark`}
      font-size: 60px;
      line-height: 80px;

      @media (min-width: 768px) {
        font-size: 70px;
      }

      @media (min-width: 1024px) {
        font-size: 110px;
      }

      @media (min-width: 1280px) {
        font-size: 130px;
      }
    }

    h3 {
      ${tw`text-xl font-fb text-primary mt-14 mb-12 md:text-2xl md:leading-8 md:mb-14 md:text-2xl lg:leading-10 lg:text-3xl lg:mt-26 lg:mb-14 xl:mt-32 xl:mb-16 xl:text-4xl`}
      line-height: 27px;

      @media (min-width: 1280px) {
        line-height: 47px;
      }
    }

    .btn-secondary {
      ${tw`mx-auto md:ml-0`}
    }
  }

  .content-col-inner {
    ${tw`text-center md:text-left `}

    @media (min-width: 768px) {
      max-width: 400px;
    }

    @media (min-width: 1024px) {
      max-width: 500px;
    }
  }
`

const NotFoundPage = () => (
  <Layout path="not-found-page">
    <SEO data={{ title: "Page Not Found!" }} />
    <NotFoundContentWrapper>
      <div className="content-col">
        <div className="content-col-inner">
          <h1>Oops!</h1>
          <h3>The page you are looking for cannot be found.</h3>
          <Link className="btn-secondary btn-sm" to="/">
            Go to Home
          </Link>
        </div>
      </div>
    </NotFoundContentWrapper>
  </Layout>
)

export default NotFoundPage
